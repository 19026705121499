@font-face {
    font-family: remachinePersonal;
    src: url("../fonts/RemachineScript_Personal_Use.ttf") format("ttf"),
       url('../fonts/RemachineScript_Personal_Use.ttf')  format('truetype'); /* Safari, Android, iOS */
}
.heroImagen{
    min-height: 90%;
    background-repeat: no-repeat;
    background-size: 120%;
    background-position: center;
    animation-name: opacidad;
    animation-duration: 1.5s;
}
a{
    color: white;
}
.degradado{
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    min-width: 100%;
    min-height: 100vh;

}
.wrapperMenu{
    display: flex;
}
#slogan{
    font-size: 1.2em;
    padding-top: 1em;
}
.hero{
    display: grid;
    min-height: 100vh;
}
.bodyCard{
    display: grid;
    grid-auto-rows: minmax(100vh, auto);
    background-size: cover;
}
.ContentHero{
    position: relative;
    margin-top: -31em;
    z-index: 2;
}
.titleHero{
    color: white;
    text-align: center;
    padding: 0.5em;
    font-size: 1.6em;
}
.logo2{
    margin-top: 2em;
    display: grid;
    justify-content: center;
    margin-bottom: 1em;
    opacity: 0.5;
}
.logo2 img{
    display: grid;
    justify-self: center;
    width: 50%;
}
.logo{
    max-width: 200px;
    display: grid;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.logo img{
    display: grid;
    justify-self: center;
    max-width: 100%;
}
.vistas{
    margin-top: 1em;
    color: white;
    justify-self: center;
    font-size: 0.7em;
}
.services{
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-auto-rows: minmax(30vh, 30vh);
    grid-column-gap: 1em;
    row-gap: 1em;
    margin-bottom: 4em;
}
.serviceTag{
    border-radius: 1em;
    -webkit-box-shadow: 0px 2px 25px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 25px -3px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 25px -3px rgba(0,0,0,0.75);
    position: relative;
    background-color: black;
}
.serviceTag{
    cursor: pointer;
}
.serviceTag:hover{
    opacity: 0.8;
    transition: 0.3s ease-in;
}
.contectDescripcion{
    max-height: 4em;
}

.imagenService{
    background-position: top;
    background-size: cover;
    min-height: 70%;
    position: relative;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
}
.imagenService::before{
    content: "";
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80%;
    background: linear-gradient(rgba(255,255,255,0),#000000);
}
.ContectService{
    text-align: center;
    color: white;
    z-index: 20;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.4em;
}
.ContectService strong{
    font-size: 1.2em;
}
.Footer{
    min-height: 100vh;
    display: grid;
    justify-content: center;
    margin-bottom: 5em;
}
.contentMedia{
    display: grid;
}
.feed{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    justify-content: center;
    align-items: center;
    padding: 1em;
    row-gap: 0.5em;
    grid-column-gap: 0.5em;
}
.feedImagen{
    max-width: 100%;
    border-radius: 1em;
}
.socialIcon{
    text-align: center;
    display: grid;
}

.socialMedia{
    padding-top: 1em;
    color: white;
    height: 30%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-content: center;
    text-align: center;
}
.greenCard{
    margin-top: 1em;
    color: white;
    text-align: center;
}
/*Animaciones*/
.hiden{
    display: none;
}
@keyframes opacidad {
    from {
        opacity: 0.4;
    }

    to {    
        opacity: 1;
    }
}
.app{
    position: relative;
}
.productos{
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-column-gap: 1em;
    row-gap: 1em;
    margin-bottom: 4em;
}
.producto{
    border-radius: 1em;
    min-height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 3fr auto;
    font-size: 1em;
}
.productosumar{
    min-width: 100%;
    min-height: 100%;
    align-items: center;
    justify-content: center;
    display: grid;
    cursor: pointer;
    text-align: center;
}
.producto_nombre{
    padding: 1em;
    position: relative;
}
.producto_imagen{
    background-position: top;
    background-size: cover;
    min-height: 100%;
    position: relative;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
}


.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

@media (min-width: 768px) {
    .app{
        display: grid;
        justify-content: center;
        grid-auto-rows: minmax(100vh, auto);
        width: 100%;
    }
    .bodyCard{
        max-width: 50vh;
    }
    .ContentHero{
        position: relative;
    }
    .titleHero{
        color: white;
        font-size: 1.2em;
        max-width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }
  }
@media(max-width:768px){
    .ContentHero{
        margin: 1em;
        margin-top: -39em !important;
    }
}